export function generateSalaryString({
    salaryTypeValue,
    salaryPeriodValue,
    basicFromSalary,
    basicToSalary,
    basicSalary,
  }: {
    salaryTypeValue: string;
    salaryPeriodValue: string;
    basicFromSalary: number;
    basicToSalary: number;
    basicSalary: number;
  }): string {
    if (!salaryTypeValue || !salaryPeriodValue) return "N/A";
  
    const formatSalary = (amount: number) => `£${amount.toFixed(2)}`;
  
    switch (salaryTypeValue) {
      case "Up to":
        return `Up To ${formatSalary(basicSalary)}  ${salaryPeriodValue}`;
  
      case "Exactly":
        return `${formatSalary(basicSalary)}  ${salaryPeriodValue}`;
  
      case "Range":
        return `${formatSalary(
          basicFromSalary
        )}  ${salaryPeriodValue} to ${formatSalary(
          basicToSalary
        )}  ${salaryPeriodValue}`;
  
      default:
        return "N/A";
    }
  }